<template lang="pug">
v-container(grid-list-md fill-height)
  template(v-if="lessons")
    .lessons
      h1.section-title Видеоуроки
      .lesson(v-for="(lesson, idx) in lessons" :key="idx")
        p {{ lesson.name }}
        .lesson__video(v-for="(link, idx) in lesson.links" :key="idx")
          youtube(:video-id="link | urlToIdYoutube")
      .lessons__not-found(v-if="!lessons.length")
        p Ничего не найдено
  template(v-else)
    the-loading
</template>

<script>
import { getIdFromUrl } from 'vue-youtube';

import MainService from '@/services/main';


export default {
  filters: {
    urlToIdYoutube(val) {
      return getIdFromUrl(val);
    },
  },
  data() {
    return {
      lessons: null,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.loadLessons();
    },
    loadLessons() {
      this.$rHandler(
        MainService.getVideoLessons(),
        (response) => { this.lessons = response.data.data; },
      );
    },
  },
};
</script>
